import qs from "qs"
import axios from "axios"
import { Dialog } from "vant"
import router from "../router"

//axios全局配置
const request = axios.create({
    //请求接口
    baseURL: "https://fuchsconnect.fuchs.com.cn/api/wx",
    // baseURL: "http://172.20.6.32:8000/api/wx",
    // baseURL: "http://ecommerce-demo-vendor.nps.worknrock.com:8022/wechat/api/wx",
    //超时设置
    timeout: 8000,
    // //跨域请求时发送cookie
    // withCredentials:true,
    //请求头设置
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: localStorage.getItem("token"),
        "X-Requested-With": "XMLHttpRequest"
    },
    transformRequest: [
        data => {
            if (data instanceof FormData || Object.prototype.toString.call(data) === "[object FormData]") {
                return data
            }
            return qs.stringify(data, {
                allowDots: false
            })
        }
    ]
})

request.interceptors.request.use(config => {
    config.headers["Authorization"] = localStorage.getItem("token")
    return config
})

request.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response.status === 401) {
            if (localStorage.getItem("token")) {
                localStorage.removeItem("token")
            }
            router.replace({
                path: "/login",
                query: {
                    redirect: location.hash.replace("#", "")
                }
            })
        }
        console.log(error.response.data.message, "=============")
        if (error.response.status >= 500) {
            Dialog.alert({
                title: "提示",
                message: error.response.data.message
            })
        }
        if (error.response.status === 400) {
            Dialog.alert({
                title: "提示",
                message: error.response.data.message
            })
        }
        if (error.response.status === 404) {
            Dialog.alert({
                title: "提示",
                message: error.response.data.message
            })
        }
        return Promise.reject(error)
    }
)

export default request
