<template>
    <div class="app">
        <keep-alive>
            <router-view />
        </keep-alive>
    </div>
</template>

<script>
export default {
    name: "app",
    data() {},
    mounted() {
        window.addEventListener("popstate", this.handleBackButton)
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.handleBackButton)
    },
    methods: {
        handleBackButton() {
            window.location.reload()
        }
    }
}
</script>

<style></style>
